import Image from "next/image";
import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import Link from "next/link";
import { Eye, FolderPlus } from "lucide-react";
import LikeButton from "./likeButton";
import { getLocalizedPath } from "../../shared/formatURL";
import AddToAlbumModal from "../../post/addToAlbumModal";
import { useGlobalContext } from "@/app/context/store";
import { useInView } from "react-intersection-observer";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  videoUrl: string;
  thumbnailUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface PictureCardProps {
  data: Post;
  locale: any;
  state: any;
  firstImg: number;
  contentType: string;
}

const PictureCard: React.FC<PictureCardProps> = ({
  data,
  locale,
  state,
  firstImg,
  contentType,
}) => {
  const { _id, imageUrl, videoUrl, thumbnailUrl, likes, userLiked, views } = data;
  const [isAddToAlbumOpen, setIsAddToAlbumOpen] = useState(false);
  const { user } = useGlobalContext();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: "300px 0px",
  });

  const setRefs = useCallback(
    (node: HTMLVideoElement | null) => {
      videoRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    if (!videoRef.current) return;

    const debounceTimeout = setTimeout(() => {
      const video = videoRef.current;
      if (!video) return;

      if (inView) {
        if (video.readyState >= 2) {
          video.play().catch((error) => {
            console.error("Video playback error:", error);
          });
        }
      } else if (!video.paused) {
        video.pause();
      }
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [inView]);

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.src = "";
        videoRef.current.load();
        videoRef.current = null;
      }
    };
  }, []);

  const videoKey = `video-${_id}`;
  const videoClasses = useMemo(
    () =>
      `${
        state.expandContent ? "" : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
      } h-full w-full object-cover`,
    [state.expandContent],
  );

  const newURL =
    contentType === "gif" ? getLocalizedPath("gif/" + _id) : getLocalizedPath("post/" + _id);

  function formatViews(number: number) {
    if (number < 1000) {
      return number.toString();
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "k";
    } else {
      return (number / 1000000).toFixed(1) + "m";
    }
  }

  return (
    <>
      <div
        className={`relative h-full w-full bg-[#2B2D31] ${
          state.expandContent ? "" : "sm:rounded-md"
        } shadow-sm`}
      >
        <Link prefetch={false} href={newURL}>
          {contentType === "gif" ? (
            <video
              key={videoKey}
              ref={setRefs}
              className={videoClasses}
              {...(inView ? { src: videoUrl } : {})}
              poster={thumbnailUrl}
              preload="metadata"
              loop
              muted
              playsInline
              disablePictureInPicture
              controlsList="nodownload nofullscreen noremoteplayback"
              autoPlay={inView}
            />
          ) : (
            <>
              {firstImg === 0 ? (
                <Image
                  className={`${
                    state.expandContent
                      ? ""
                      : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
                  }`}
                  src={imageUrl}
                  alt={_id + " | AI Porn Generator"}
                  layout="responsive"
                  width={2}
                  height={3}
                  loading="eager"
                  priority={true}
                />
              ) : (
                <Image
                  className={`${
                    state.expandContent
                      ? ""
                      : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
                  }`}
                  src={imageUrl}
                  alt={_id + " | AI Porn Generator"}
                  layout="responsive"
                  width={2}
                  height={3}
                  loading="lazy"
                />
              )}
            </>
          )}
        </Link>
        {((state.viewPorts.default < 5 && contentType === "image") ||
          (state.viewPorts.default < 4 && contentType === "gif")) && (
          <div className="absolute bottom-2 right-0 flex flex-col gap-1">
            <Link prefetch={false} href={newURL}>
              <div className="mx-2 flex flex-col items-center rounded-lg bg-[#2B2D31]/20 p-1 backdrop-blur-xl hover:bg-[#DD0772]">
                <Eye color="white" className="h-5 w-5" />
                <div className="text-[12px] text-white">{formatViews(views)}</div>
              </div>
            </Link>
            <LikeButton
              initialLikes={likes}
              id={_id}
              userLiked={userLiked}
              locale={locale}
              newURL={newURL}
            />
            {contentType === "image" && user._id !== "none" && !user.temp && (
              <button
                onClick={() => setIsAddToAlbumOpen(true)}
                className="mx-2 flex flex-col items-center rounded-lg bg-[#2B2D31]/20 p-1 backdrop-blur-xl hover:bg-[#DD0772]"
              >
                <FolderPlus className="h-5 w-5 text-white" />
              </button>
            )}
          </div>
        )}
      </div>
      <AddToAlbumModal
        isOpen={isAddToAlbumOpen}
        setIsOpen={setIsAddToAlbumOpen}
        postIds={[_id]}
        locale={locale}
      />
    </>
  );
};

export default PictureCard;
