"use client";
import React from "react";
import PictureCard from "./pictureCard";
import MysnoryLayout from "./mysnoryLayout";
import { useDisplayModeContext } from "../../../app/context/displayModeStore";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  videoUrl: string;
  thumbnailUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface CardWrapperProps {
  data: Post[];
  locale: any;
  contentType: string;
}

const CardWrapper: React.FC<CardWrapperProps> = ({ data, locale, contentType }) => {
  const { state } = useDisplayModeContext();
  return (
    <MysnoryLayout state={state}>
      {data.map((card: Post, i: number) => (
        <PictureCard
          key={i}
          firstImg={i}
          data={card}
          locale={locale}
          state={state}
          contentType={contentType}
        />
      ))}
    </MysnoryLayout>
  );
};

export default CardWrapper;
