"use client";
import { useRef, useEffect, useState, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import useSWRInfinite from "swr/infinite";
import Image from "next/image";
import Link from "next/link";
import { ChevronUp, Expand, Move, ChevronRight, ChevronLeft } from "lucide-react";
import CardWrapper from "./pictureCard/cardWrapper";
import { generators, gifReady } from "../shared/generators/generators";
import axiosInstance from "@/lib/axios/axiosInstance";
import { CategoryText } from "./CategoryText";
// import { ValidLocale } from "i18n";
import { useDisplayModeContext } from "../../app/context/displayModeStore";
import { getLocalizedPath } from "../shared/formatURL";
import { useGlobalContext } from "../../app/context/store";
import { Gem } from "lucide-react";
import cookie from "cookie";
import { TopTabLink } from "./topTabLink";
import { useRouter } from "next/navigation";
// import local from "next/font/local";
import { findStyleNameByIdAndLocale } from "../shared/generators/generators";
import { useStyleSelectModal } from "../shared/style-select-modal";
import { useLocale } from "@/app/context/LocaleContext";
import StyleToggle from "../shared/styleToggle";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  videoUrl: string;
  thumbnailUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface PageData {
  info: {
    count: number;
    next: string | null;
    pages: number;
    prev: string | null;
  };
  results: Post[];
}

interface TabLinkProps {
  label: string; // e.g., 'top', 'new', 'all'
  current: string; // current tab or generator
  setCurrent: any; // function to set the current tab or generator
  isTab?: boolean; // differentiate between tab and generator
}

// const toggleGenerator = (genIds: string[], genId: string) => {
//   let result;
//   if (genIds.includes("all")) {
//     result = [genId];
//   } else if (genIds.includes(genId)) {
//     const newArray = genIds.filter((id) => id !== genId);
//     result = newArray.length > 0 ? newArray : ["all"];
//   } else {
//     result = [...genIds, genId];
//   }
//   return result.sort();
// };

export function InfiniteScrollWrapper({ contentType }: { contentType: string }) {
  const { locale } = useLocale();
  const { user } = useGlobalContext();
  const { state, dispatch } = useDisplayModeContext();
  const [sliderValue, setSliderValue] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);
  const [fullScreen, setFullScreen] = useState(false);
  const searchParams = useSearchParams();
  const pathname = usePathname();

  // Ref for the scrollable container
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Function to scroll left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -500, behavior: "smooth" });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 500, behavior: "smooth" });
    }
  };

  // Extract language and country from the pathname, defaulting to 'en' and 'US'

  // Construct the base URL
  // const constructBaseURL = (lang: string, country: string) => {
  //   return lang === "en" && country === "US" ? "" : `/${lang}/${country}`;
  // };

  // State hooks for current tab, generator, and scroll visibility
  const [currTab, setCurrTab] = useState(searchParams.get("filter") || "hot");

  // const [currGenerators, setCurrGenerators] = useState(
  //   (searchParams.get("style")?.split(",") || ["all"]) as string[],
  // );

  // Inside your component
  const router = useRouter();

  // Extract the 'style' query parameter
  const styleParam = searchParams.get("style") || "all";

  // Check if 'styleParam' contains a comma
  const processedStyleParam = styleParam.includes(",") ? "all" : styleParam;

  const [currGenerator, setCurrGenerator] = useState(processedStyleParam);

  const setCurrGeneratorFromStr = (currGenStr: string) => {
    setCurrGenerator(currGenStr);
  };

  const [showScrollTop, setShowScrollTop] = useState(false);
  // const hasSearchParam = Boolean(searchParams.get("search"));

  // Derived states
  // const queryParam = searchParams.get("search");
  const newURL = locale.locale.path + (contentType === "gif" ? "gifs" : ""); //constructBaseURL(defaultLang, defaultCountry);
  const baseEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/collection`;
  // const searchEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/search`;
  const baseCacheEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/feed`;
  const baseGifEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/gifs`;

  // Add type state initialized from URL query param

  const constructUrl = (tab: string, generator: string, index: number): string => {
    if (contentType === "gif") {
      const baseParams = `limit=20&include=userLikes`;
      const tabParams = tab === "hot" ? `&sort=top` : `&sort=new`;
      return `${baseGifEndpoint}?${baseParams}${tabParams}`;
    } else {
      const baseParams = `limit=10&include=userLikes&images=1&views=1&likes=1`;
      const generatorParam =
        generator !== "all" ? `&generator=1&generatorId=${encodeURIComponent(generator)}` : "";
      // const queryParamPart = queryParam
      //   ? `&searchQuery=${encodeURIComponent(queryParam)}`
      //   : "";

      const tabParams: { [key: string]: string } = {
        new: `&timespan=0${index === 0 ? "&date=1" : ""}`,
        hot: `&timespan=1${index === 0 ? "&sort=top" : ""}`,
        top: `&timespan=0${index === 0 ? "&sort=top" : ""}`,
        top2: `&timespan=2${index === 0 ? "&sort=top" : ""}`,
        top7: `&timespan=7${index === 0 ? "&sort=top" : ""}`,
        top15: `&timespan=15${index === 0 ? "&sort=top" : ""}`,
      };

      if (!tabParams[tab]) {
        throw new Error("Invalid tab provided");
      }

      if (tab === "top2" || tab === "top7" || tab === "hot" || tab === "top15") {
        const baseCacheParams = `include=userLikes`;
        const generatorCacheParams =
          generator !== "all" ? `&generatorId=${encodeURIComponent(generator)}` : "";
        const typeCacheParams = `&type=${tab}`;
        return `${baseCacheEndpoint}?${baseCacheParams}${typeCacheParams}${generatorCacheParams}`;
      }

      let endpointResult = `${baseEndpoint}?${baseParams}${tabParams[tab]}${generatorParam}`; //${queryParamPart}

      // if (queryParamPart != "") {
      //   endpointResult = `${searchEndpoint}?${
      //     locale.key === "en-US" ? "" : `locale=${locale.key}&`
      //   }${baseParams}${queryParamPart}`;
      // } else {
      // }
      return endpointResult;
    }
  };

  const getKey = useMemo(() => {
    return (pageIndex: any, previousPageData: any) => {
      // If the first page, no need to check the previous page data
      if (pageIndex === 0) {
        return constructUrl(currTab, currGenerator, pageIndex);
      }

      // Check if the previous page data has exactly 10 items
      if (previousPageData && previousPageData.results.length < 10) {
        // If less than 10 items, don't fetch more
        return null;
      }

      // If previous page data is empty or it's the end of pagination, return null
      if (!previousPageData || previousPageData.info.next === null) {
        return null;
      }

      // Return the URL for the next page
      return previousPageData.info.next;
    };
  }, [currTab, currGenerator, contentType]);

  const fetcher = async (url: string) => {
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const TabLink: React.FC<TabLinkProps> = ({ label, current, setCurrent, isTab = true }) => {
    const query: any = {
      filter: isTab ? label : currTab,
    };

    if (contentType === "image") {
      query.style = isTab ? currGenerator : label;
    }

    return (
      <Link
        prefetch={false}
        href={{ pathname: newURL, query }}
        onClick={() => setCurrent(label)}
        className={`${
          current === label
            ? "border-[0.5px] border-[#DD0772] bg-[#DD0772]"
            : "border-[0.5px] border-[#3C3C3C] bg-[#2B2D31]"
        } xs:ml-0 mr-2.5 whitespace-nowrap rounded-md px-4 py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300`}
      >
        {locale.home[label]}
      </Link>
    );
  };

  const { data, error, setSize, size } = useSWRInfinite<PageData>(getKey, fetcher, {
    revalidateOnFocus: false, // Prevent revalidation on focus
    revalidateAll: false, // Prevent revalidation of all pages
    revalidateFirstPage: false, // Prevent revalidation of the first page
  });
  const infiniteScrollBoundaryRef = useRef<HTMLDivElement | null>(null);
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (data && typeof data[size - 1] === "undefined");
  const isReachingEnd =
    data &&
    (data[data.length - 1]?.info.next === null || data[data.length - 1]?.results.length === 0);
  const posts = data // Flatten the nested array to a single array
    ? ([] as Post[]).concat(...data.map((page) => page.results))
    : [];

  // const nextPage = data && data[data.length - 1]?.info.next;
  const scrollToTop = () => {
    // This function will scroll the window to the top
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSliderChange = (event: any) => {
    setSliderValue(event.target.value);
    // Serialize the cookie string using the `cookie` library
    const serializedCookie = cookie.serialize("sliderValue", event.target.value, {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 999,
    });
    document.cookie = serializedCookie;

    if (contentType === "gif") {
      // GIF layout settings
      switch (event.target.value) {
        case "0":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 2, lg: 2, md: 1, sm: 1 },
          });
          break;
        case "1":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 3, lg: 3, md: 2, sm: 2 },
          });
          break;
        case "2":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 4, lg: 4, md: 3, sm: 3 },
          });
          break;
        case "3":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 5, lg: 5, md: 3, sm: 3 },
          });
          break;
        default:
          console.error("Unexpected slider value");
      }
    } else {
      // Original image layout settings
      switch (event.target.value) {
        case "0":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 3, lg: 2, md: 2, sm: 1 },
          });
          break;
        case "1":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 4, lg: 3, md: 2, sm: 2 },
          });
          break;
        case "2":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 5, lg: 4, md: 3, sm: 3 },
          });
          break;
        case "3":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 6, lg: 5, md: 4, sm: 4 },
          });
          break;
        case "4":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 7, lg: 6, md: 5, sm: 5 },
          });
          break;
        case "5":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 8, lg: 7, md: 6, sm: 5 },
          });
          break;
        case "6":
          dispatch({
            type: "SET_VIEWING_MODE",
            payload: { default: 9, lg: 8, md: 7, sm: 6 },
          });
          break;
        default:
          console.error("Unexpected slider value");
      }
    }
  };

  const updateRangeValues = () => {
    const screenWidth = window.innerWidth;
    if (contentType === "gif") {
      if (screenWidth < 768) {
        setMinValue(0);
        setMaxValue(2); // Mobile: 1-3 columns
      } else {
        setMinValue(0);
        setMaxValue(2); // Desktop: 2-4 columns
      }
    } else {
      if (screenWidth < 768) {
        setMinValue(0);
        setMaxValue(4);
      } else {
        setMinValue(0);
        setMaxValue(6);
      }
    }
  };

  function toggleFullScreen() {
    setFullScreen(!fullScreen);
    if (!document.fullscreenElement) {
      // Enter full screen
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  function toggleExpandContent() {
    // Serialize the cookie string using the `cookie` library
    const serializedCookie = cookie.serialize("expandContent", String(!state.expandContent), {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 999, // Max age in seconds
    });
    // Set the cookie in the browser
    document.cookie = serializedCookie;
    dispatch({ type: "SET_EXPAND_CONTENT", payload: "" });
  }

  useEffect(() => {
    if (styleParam.includes(",")) {
      // Update the URL parameter 'style' to 'all'
      const newSearchParams = new URLSearchParams(Array.from(searchParams.entries()));
      newSearchParams.set("style", "all");

      // Replace the current URL without adding a new entry to the history stack
      router.replace(`${pathname}?${newSearchParams.toString()}`);

      // Update the state
      setCurrGenerator("all");
    }

    updateRangeValues();

    const parsedCookies = cookie.parse(document.cookie);
    if (parsedCookies.sliderValue) {
      handleSliderChange({ target: { value: parsedCookies.sliderValue } });
    }

    if (parsedCookies.expandContent) {
      if (parsedCookies.expandContent === "true") {
        dispatch({ type: "SET_EXPAND_CONTENT", payload: true });
      }
    }

    window.addEventListener("resize", updateRangeValues);

    // Inline function for scroll event handling
    const handleScroll = () => {
      const shouldShow = window.scrollY > 200;
      setShowScrollTop(shouldShow);
    };
    // Setup the scroll listener
    window.addEventListener("scroll", handleScroll);
    // Inline function for intersection handling and observer setup
    let observer: IntersectionObserver | null = null;
    if (!isLoadingMore && !error && infiniteScrollBoundaryRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setSize(size + 1);
          }
        },
        { threshold: 1 },
      );
      observer.observe(infiniteScrollBoundaryRef.current);
    }

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateRangeValues);
      observer?.disconnect();
    };
  }, [isLoadingMore, setSize, size, error, styleParam]);

  useEffect(() => {
    if (contentType === "gif" && sliderValue > 2) {
      setSliderValue(2);
      dispatch({
        type: "SET_VIEWING_MODE",
        payload: { default: 4, lg: 4, md: 3, sm: 3 },
      });
    }
  }, [contentType]);

  const updateStyleParam = (newStyle: any) => {
    setCurrGenerator(newStyle);
  };

  return (
    <div className="relative -mt-4 sm:-mt-6">
      {gifReady() && (
        <div className="mx-auto flex flex-col">
          <div className="flex flex-row justify-center">
            <Link
              prefetch={false}
              href={{
                pathname: "/",
              }}
              className={`rounded-md py-1 text-sm font-bold ${
                contentType === "image" ? "text-white" : "text-gray-300"
              }
           `}
            >
              {locale.images || "Images"}
            </Link>
            <div className="mx-4 h-5 w-[1px] self-center bg-[#3C3C3C]" />
            <Link
              prefetch={false}
              href={{
                pathname: "/gifs",
              }}
              className={`rounded-md py-1  text-sm font-bold ${
                contentType === "gif" ? "text-white" : "text-gray-300"
              }
           `}
            >
              {locale.gifs || "Gifs"}
            </Link>
          </div>
          <div className="relative left-[50%] right-[50%] -mx-5 mb-4 ml-[-50vw] mr-[-50vw] mt-2 w-[100vw] border-b-[0.5px]  border-[#3C3C3C]" />
        </div>
      )}
      <div className="mx-auto px-5 xl:max-w-[88rem]">
        <CategoryText
          sortBy={currTab === "top2" || currTab === "top7" || currTab === "top15" ? "top" : currTab}
          style={currGenerator === "all" ? ["all"] : [currGenerator]}
          pathCode={locale.locale.key}
          queryParam={""}
          contentType={contentType}
        />
        <div className={`z-10 flex items-center`}>
          {/* <button
            onClick={scrollLeft}
            aria-label="Left Arrow"
            className="scroll-arrow left-arrow z-10 hidden border-none bg-[#202124] px-2 py-1.5 focus:outline-none sm:flex"
          >
            <ChevronLeft className="text-white" />
          </button> */}
          <div
            ref={scrollContainerRef}
            className="xs:mx-0 mx-[-18px] flex items-center overflow-hidden overflow-x-scroll whitespace-nowrap scrollbar-hide sm:w-full"
          >
            <div className="ml-5 flex">
              <TabLink label="hot" current={currTab} setCurrent={setCurrTab} />
            </div>
            <TabLink label="new" current={currTab} setCurrent={setCurrTab} />

            {contentType === "gif" && <></>}

            {contentType === "image" && (
              <>
                <TopTabLink label="top" current={currTab} setCurrent={setCurrTab} newURL={newURL} />
                <div className="mr-2.5 inline-block h-4 border-r border-white"></div>

                <TabLink
                  label="all"
                  current={currGenerator}
                  setCurrent={setCurrGeneratorFromStr}
                  isTab={false}
                />
                <StyleToggle styleParam={styleParam} updateStyleParam={updateStyleParam} />
              </>
            )}

            {/* {generators.map((genre, index) => {
              const labelObj = genre?.styleName.find((langObj) => langObj.name === locale.key);
              const label = labelObj ? labelObj.value : "No Label"; // Defaulting to 'No Label' if not found
              const isSelected = currGenerator === genre.id;
              return (
                <Link
                  prefetch={false}
                  key={genre.id}
                  href={{
                    pathname: newURL,
                    query: {
                      filter: currTab,
                      style: genre.id,
                      // ...(queryParam && { search: queryParam }), // Include search parameter if present
                    },
                  }}
                  onClick={() => {
                    setCurrGenerator(genre.id);
                  }}
                  className={`${
                    isSelected
                      ? "border-[0.5px] border-[#DD0772] bg-[#DD0772]"
                      : "border-[0.5px] border-[#3C3C3C] bg-[#2B2D31]"
                  } ${
                    index === generators.length - 1 ? "mr-[18px]" : "mr-2.5"
                  } whitespace-nowrap rounded-md px-4 py-[7px] text-sm font-medium font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300`}
                >
                  {label}
                </Link>
              );
            })} */}
          </div>
          {/* <button
            onClick={scrollRight}
            aria-label="Right Arrow"
            className="scroll-arrow right-arrow z-10 ml-5 hidden border-none bg-[#202124] px-2 py-1.5 focus:outline-none sm:flex"
          >
            <ChevronRight className="text-white" />
          </button> */}
        </div>
        <div className="mb-3.5 mt-2 flex flex-row items-center justify-center">
          <div className="flex-grow">
            <input
              type="range"
              aria-label="Slider"
              min={minValue}
              max={maxValue}
              value={sliderValue}
              onChange={handleSliderChange}
              className="h-2 w-full cursor-pointer appearance-none rounded-lg border-[0.5px] border-[#3C3C3C] bg-[#2B2D31]"
            />
          </div>
          <button
            onClick={toggleExpandContent}
            className={`${
              false ? "" : ""
            } ml-4 hidden flex-row overflow-hidden whitespace-nowrap rounded-md py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300 sm:flex`}
          >
            <Move color="white" className="mx-1 my-auto h-3.5 w-auto" />
            {state.expandContent ? locale.home.shrinkContent : locale.home.expandContent}
          </button>
          <button
            onClick={toggleFullScreen}
            className={`${
              false ? "" : ""
            } ml-4 hidden flex-row rounded-md py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300 sm:flex`}
          >
            <Expand color="white" className="mx-1 my-auto h-3.5 w-auto" />
            {fullScreen ? locale.home.exitFullScreen : locale.home.fullScreen}
          </button>
        </div>
      </div>
      <div
        className={`mx-auto mb-[100px] ${
          state.expandContent ? "" : "px-0 sm:px-[7px] xl:max-w-[88rem]"
        }`}
      >
        {/* Images */}
        <CardWrapper data={posts} locale={locale} contentType={contentType} />
        <div ref={infiniteScrollBoundaryRef} className="-mt-[55vh]" />
        {/* Loading Icon */}
        {isLoadingMore && !isReachingEnd && !error && posts.length % 10 === 0 && (
          <div className="flex">
            <Image
              src={"https://cdn6.imgpog.com/loadingGIF.webp"}
              width={50}
              height={50}
              alt="Loading"
              className="mx-auto mb-[55vh] mt-[55vh]"
              unoptimized
            />
          </div>
        )}
        {/* Empty State */}
      </div>
      <div className="fixed bottom-0 flex h-[6.25rem] w-full bg-gradient-to-t from-[#131315] via-[#131315]/70 to-transparent sm:bottom-16 sm:h-0 sm:max-w-[80rem] sm:bg-none">
        {/* Premium Icon */}
        <button
          className={`${
            user.membership && user?.membership.level === 1 ? "hidden" : "block"
          } block flex w-auto pl-10 sm:hidden`}
          aria-label="Upgrade"
        >
          <Link
            prefetch={false}
            href={getLocalizedPath("upgrade") + "?ref=scroll"}
            aria-label="Upgrade"
            className="mb-auto mr-auto flex animate-fade-up-sped rounded-full bg-[#FFCC18] px-3 py-2 text-sm font-bold text-white shadow-2xl hover:brightness-105"
          >
            <Gem color="black" className="mx-0.5 my-auto h-5 w-auto px-[0.85px] py-0.5" />
          </Link>
        </button>
        {showScrollTop && (
          <div className={`z-1000 ml-auto pr-10`}>
            <button
              onClick={scrollToTop}
              className="flex animate-fade-up-sped rounded-full bg-[#DD0772] px-3 py-2 text-sm font-bold text-white shadow-2xl hover:brightness-105 sm:mt-[-2.25rem]"
            >
              <ChevronUp color="white" className="my-auto h-5 w-auto" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
